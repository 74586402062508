import { post } from "../axiosConfig";

export const TranslationAPI = {
    async translateFields(languages: string[], values: Record<string, string>): Promise<Record<string, string>> {
        const { data } = await post('/api/v1/admin/translate', {
            languages,
            values
        });
        return data;
    }
};