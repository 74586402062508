import { reduceList } from "../utils/reduceList";

const aiSetting = [
    "apiKey",
    "version",
    "textPrompt",
    "maxToken",
    "wineImagePrompt",
    "menuImagePrompt",
];

export const aiSettingFields = reduceList(aiSetting);