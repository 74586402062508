import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { useController } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { TabOptions, TernaryTabList } from "../TernaryTabList";
import { Button } from '../../../ui/atoms/Button';
import { Spinner } from "../../atoms/Spinner";
import { TranslationAPI } from '../../../features/utils/api/requests/ai-requests';
interface LanguageChangerProps {
    name: string;
    fields?: string[];
    onLoadingChange?: (isLoading: boolean) => void;
}

export const langTabs: TabOptions[] = [
    {
        label: "English",
        Icon: "🇬🇧",
        value: "en"
    },
    {
        label: "Russian",
        Icon: "🇷🇺",
        value: "ru"
    },
    {
        label: "Italian",
        Icon: "🇮🇹",
        value: "it"
    },
    {
        label: "French",
        Icon: "🇫🇷",
        value: "fr"
    },
    {
        label: "German",
        Icon: "🇩🇪",
        value: "de"
    },
    {
        label: "Spanish",
        Icon: "🇪🇸",
        value: "es"
    }
];

export const LanguageTabChanger: FC<LanguageChangerProps> = observer(
    ({ name, fields, onLoadingChange }) => {
        const { control, getValues, setValue } = useFormContext();
        const [isLoading, setIsLoading] = useState(false);
        const {
            field: { value, onChange }
        } = useController({ name, control });

        const handleAITranslation = async () => {
            setIsLoading(true);
            if (onLoadingChange) onLoadingChange(true);
            if (fields && fields.length > 0) {
                for (const field of fields) {
                    const values = langTabs.reduce((acc, lang) => {
                        acc[lang.value] = getValues(`${field}.${lang.value}`);
                        return acc;
                    }, {} as Record<string, string>);

                    try {
                        const translations = await TranslationAPI.translateFields(
                            langTabs.map(lang => lang.value),
                            values
                        );

                        Object.entries(translations).forEach(([lang, text]) => {
                            setValue(`${field}.${lang}`, text);
                        });
                    } catch (error) {
                        console.error(`Error translating ${field}:`, error);
                    }
                }
            }
            setIsLoading(false);
            if (onLoadingChange) onLoadingChange(false);
        };

        return (
            <div>
                <TernaryTabList
                    value={value}
                    onChange={onChange}
                    options={langTabs}
                />
                {fields && fields.length > 0 && (
                    <Button
                        htmlType="button"
                        text={isLoading ? "Translating..." : "AI Translate"}
                        onClick={handleAITranslation}
                        className="mt-5"
                        isDisabled={isLoading}
                    >
                        {isLoading && <Spinner />}
                    </Button>
                )}
            </div>
        );
    }
);
