import { joiResolver } from "@hookform/resolvers/joi";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../ui/atoms/Button";
import { Input } from "../../../../../ui/atoms/Input";
import { throwErrorToast, throwSuccessToast } from "../../../../../ui/organisms/Toaster";
import { useModal } from "../../../../modalpage/hooks";
import { AiSettings } from "../../../../types";
import { SettingsAPI } from "../../../../utils/api/requests/settings-requests";
import { getAiSettingSchema } from "../../../../utils/schemas/AiSettingSchema";
import { FormInput } from "../../../../../ui/atoms/FormInput";
import { FormTextarea } from "../../../../../ui/atoms/FormTextarea";

export const UPDATE_AI_SETTINGS = "UPDATE_AI_SETTINGS";
interface UpdateAiSettingsModalProps {
    id: string;
}

export const UpdateAiSettingsModal: FC<UpdateAiSettingsModalProps> = observer(
    ({ id }) => {
        const { t } = useTranslation();
        const [savedSettings, setSavedSettings] = useState<AiSettings | null>(null);
        const { activeModalId, closeModal, modalData, modalCallback } = useModal<{
            item: AiSettings;
        }>();

        const formMethods = useForm<AiSettings>({
            mode: "onSubmit",
            reValidateMode: "onChange",
            resolver: joiResolver(getAiSettingSchema(t))
        });
        const { reset, handleSubmit } = formMethods;

        useEffect(() => {
            const getSettings = async () => {
                const existingSettings = await SettingsAPI.getAiSettings();
                console.log("existingSettings", existingSettings);
                if (existingSettings) {
                    setSavedSettings(existingSettings);
                    reset({
                        apiKey: existingSettings.apiKey,
                        version: existingSettings.version,
                        textPrompt: existingSettings.textPrompt,
                        maxToken: existingSettings.maxToken,
                        wineImagePrompt: existingSettings.wineImagePrompt,
                        menuImagePrompt: existingSettings.menuImagePrompt
                    });
                }
            };
            if (activeModalId === UPDATE_AI_SETTINGS) {
                getSettings();
            }
        }, [activeModalId, reset]);

        const [isLoading, setLoading] = useState<boolean>(false);

        const onSubmit = async (data: AiSettings) => {
            setLoading(true);
            try {
                let result;
                if (savedSettings) {
                    result = await SettingsAPI.updateAiSettings(data, savedSettings._id);
                } else {
                    result = await SettingsAPI.createAiSettings(data);
                }
                if (result) {
                    closeModal();
                    throwSuccessToast(
                        (modalData?.item ? t("aiSettings.aiSettingsUpdated") : t("aiSettings.aiSettingsAdded"))
                    );
                    const cb = modalCallback[UPDATE_AI_SETTINGS];
                    cb && cb(true);
                }
            } catch (error) {
                // Handle error
            } finally {
                setLoading(false);
            }
        };

        if (activeModalId !== id) return null;
        return (
            <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <section className="w-1200p bg-white rounded-20p flex flex-col text-dark-main">
                        <h4 className="text-18 font-semibold p-6 mb-10p">
                            {t("aiSettings.title")}
                        </h4>
                        <section className="flex-col px-25p mb-4">
                            <div className="grid grid-cols-3 gap-4 mr-5" style={{ gridTemplateColumns: '2fr 1fr 1fr' }}>
                                <FormInput
                                    label={t("aiSettings.apiKey")}
                                    placeholder={t("aiSettings.apiKeyPlaceholder")}
                                    name="apiKey"
                                />
                                <FormInput
                                    label={t("aiSettings.version")}
                                    placeholder={t("aiSettings.versionPlaceholder")}
                                    name="version"
                                />
                                <FormInput
                                    label={t("aiSettings.maxToken")}
                                    name="maxToken"
                                    placeholder={t("aiSettings.maxTokenPlaceholder")}
                                    isMaskedNumber={true}
                                />
                            </div>
                        </section>
                        <section className="flex-col px-25p mb-4">
                            <FormTextarea
                                name="textPrompt"
                                label={t("aiSettings.textPrompt")}
                                placeholder={t("aiSettings.textPromptPlaceholder")}
                                textareaClasses="resize-none min-h-170p"
                            />
                        </section>
                        <section className="flex-col px-25p mb-4">
                            <div className="grid grid-cols-2 gap-4 mr-5">
                                <FormTextarea
                                    name="wineImagePrompt"
                                    label={t("aiSettings.wineImagePrompt")}
                                    placeholder={t("aiSettings.wineImagePromptPlaceholder")}
                                    textareaClasses="resize-none min-h-170p"
                                />
                                <FormTextarea
                                    name="menuImagePrompt"
                                    label={t("aiSettings.menuImagePrompt")}
                                    placeholder={t("aiSettings.menuImagePromptPlaceholder")}
                                    textareaClasses="resize-none min-h-170p"
                                />
                            </div>
                        </section>
                        <div className="w-full bg-gray-bg p-6 flex rounded-b-20p ">
                            <Button
                                text={modalData?.item._id ? t("save") : t("add")}
                                isDisabled={isLoading}
                            />
                            <Button
                                text={t("cancel_2")}
                                type="tertiary"
                                className="ml-10p"
                                htmlType="button"
                                onClick={closeModal}
                                isDisabled={isLoading}
                            />
                        </div>
                    </section>
                </form >
            </FormProvider >
        );
    }
);
